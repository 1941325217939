@import '../../../../shared/styles/variable';

.appDrawerLayout {
  min-height: 100vh;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  &.appMainFixedFooter {
    padding-bottom: 46px;

    & .appFooter {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      width: 100%;
    }
  }

  &.boxedLayout {
    @include media-breakpoint-up(xxl) {
      margin-left: auto;
      margin-right: auto;
      max-width: 1360px;
      border-left: solid $border-width $border-color;
      border-right: solid $border-width $border-color;
    }
  }

  &.framedLayout {
    @include media-breakpoint-up(xxl) {
      padding: 20px;
      background-color: $primary;

      & .appDrawerLayoutInner {
        border-radius: 12px;
        background-color: $body-bg;
      }

      & .appDrawerLayoutHeader {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      & .appFooter {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      & .drawerLayoutMainScrollbar {
        max-height: calc(100vh - 110px);
      }

      & :global(.apps-container) {
        height: calc(100vh - 212px);
      }
    }
  }
}

.appDrawerLayoutInner {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  flex: 1;
}

.appDrawerLayoutSidebar {
  max-width: none !important;
  min-width: 0 !important;
  width: 100% !important;
}

.appDrawerLayoutSidebarInner {
  display: flex;
  flex-direction: column;
}

.appDrawerLayoutHeader {
  padding-left: 20px;
  padding-right: 20px;
  color: $text-color;
  background-color: $component-bg;
  border-bottom: solid $border-width $border-color;
  height: 56px;
  line-height: 1;
  transition: all 0.1s linear;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(md) {
    padding-left: 30px;
    padding-right: 30px;
    height: 70px;
  }

  & .trigger {
    font-size: 20px;
    margin-right: 20px;
    padding: 5.5px;
    color: $text-color;
    cursor: pointer;

    & svg {
      display: block;
    }

    [dir=rtl] & {
      margin-right: 0;
      margin-left: 20px;
    }
  }
}

.appDrawerLayoutMain {
  transition: all 0.1s linear;
  width: 100% !important;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.drawerLayoutMainScrollbar {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  max-height: calc(100vh - 56px);

  .appMainFixedFooter & {
    max-height: calc(100vh - 104px);
  }

  @include media-breakpoint-up(md) {
    max-height: calc(100vh - 70px);

    .appMainFixedFooter & {
      max-height: calc(100vh - 118px);
    }
  }
}

.appDrawerLayoutSidebarScrollbar {
  height: calc(100vh - 56px) !important;

  .appMainFixedFooter & {
    height: calc(100vh - 102px) !important;
  }

  @include media-breakpoint-up(md) {
    height: calc(100vh - 70px) !important;

    .appMainFixedFooter & {
      height: calc(100vh - 116px) !important;
    }
  }
}

// Sidebar Background Image
.drawerLayoutSidebarImgBackground {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    z-index: 1;
    opacity: 0.5;
  }

  & > div {
    position: relative;
    z-index: 3;
  }
}

.appDrawer {
  width: 17.5rem !important;
}

.dropdownToggleBtn {
  color: inherit;
  text-align: left;
  font-size: $font-size-base;

  [dir=rtl] & {
    text-align: right;
  }

  &:after {
    display: none;
  }
}



