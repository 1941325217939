@import '../../../shared/styles/variable.scss';

.messageListItem {
  padding: 8px 20px;
  line-height: 1.4;
  border: 0 none;
}

.messageAvatar {
  width: 40px;
  height: 40px;
  margin-right: 12px;

  [dir=rtl] & {
    margin-right: 0;
    margin-left: 12px;
  }

  @include media-breakpoint-up(md) {
    margin-right: 16px;
    width: 48px;
    height: 48px;

    [dir=rtl] & {
      margin-right: 0;
      margin-left: 16px;
    }
  }
}

.messageListItemContent {
  font-size: $font-size-base;
  color: $text-color-secondary;

  & h3 {
    margin-bottom: 2px;
    font-weight: $font-weight-medium;
    font-size: $font-size-base;
    color: $text-color;
  }

  & p {
    margin-bottom: 0;
  }

  & span {
    color: $text-color;
    font-weight: $font-weight-medium;
    margin-right: 8px;
  }
}