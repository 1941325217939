@import '../../../shared/styles/variable.scss';

.customizeDrawer {
  @include media-breakpoint-down(sm) {
    width: 300px;
  }
}

.customizerOption {
  position: fixed;
  right: 0;
  top: 85px;
  z-index: 9;

  [dir='rtl'] & {
    right: auto;
    left: 0;
  }

  @include media-breakpoint-up(xxl) {
    top: 125px;
  }
}

.customizerBtn {
  border-radius: 30px 0 0 30px;
  background-color: $orange;
  color: $white;
  box-shadow: none;
  border: 0 none;
  height: 48px;
  width: 48px;
  padding: 12px;

  &:hover, &:focus {
    background-color: $orange;
    color: $white;
    border-radius: 30px 0 0 30px;
  }

  & i {
    font-size: 24px;
  }

  [dir='rtl'] & {
    border-radius: 0 30px 30px 0;
  }
}

.customizeHeader {
  padding: 20px;
  border-bottom: solid 1px $border-color;

  @include media-breakpoint-up(xxl) {
    padding: 28px 22px;
  }

  & h3 {
    margin-bottom: 2px;
    font-size: 18px;
  }

  & p {
    margin-bottom: 0;
    color: $text-color-secondary;
  }
}

.customizeMain {
  padding: 20px;

  @include media-breakpoint-up(xxl) {
    padding: 28px 22px;
  }
}

.customizeItem {
  position: relative;

  &:not(:last-child) {
    border-bottom: solid 1px $border-color;
    padding-bottom: 20px;
    margin-bottom: 20px;

    @include media-breakpoint-up(xxl) {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
  }

  & h4 {
    margin-bottom: 8px;

    @include media-breakpoint-up(xxl) {
      margin-bottom: 12px;
    }
  }
}

.colorOptionListItem {
  padding: 0 5px;
  margin-bottom: 10px;
}

.customizeNavOption {
  margin-left: -10px;
  margin-right: -10px;
}

.customizeNavOptionItem {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.customizeNavOptionContent {
  position: relative;
  cursor: pointer;

  & .layout-img {
    width: 72px;
  }
}

.customizeNavOptionRightIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  background-color: $primary;
  color: $white;
}

.customColorOption {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border:  1px solid $border-color;
  height: 40px;
  width: 50px;
}

.customColorOptionRightIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  background-color: $body-bg;
  color: $primary;
}

.customColorSwitch {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 10px;
}

.customColorSwitchColor {
  width: 30px;
  height: 16px;
  background-color: $primary;
  border: 1px solid $border-color;
  margin-right: 10px;

  @include media-breakpoint-up(xxl) {
    width: 40px;
    height: 26px;
  }
}

.secondaryColorSwitchColor {
  background-color: $secondary;
}

.sidebarColorSwitchColor {
  background-color: $primary;
}

.primaryColorPopover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  [dir='rtl'] & {
    left: auto;
    right: 0;
  }
}

.customizeSelectBox {
  width: 100%;
}

.formSwitchInput {
  cursor: pointer;
}