@import '../../../shared/styles/variable.scss';

.appIconBtn {
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: $body-bg;
  color: $text-color-secondary;
  padding: 5px;
  border-radius: $border-radius-circle;
  border: solid 1px transparent;
  line-height: 1;
  cursor: pointer;

  &:hover, &:focus, &:global(.active), &:active:focus {
    color: $text-color;
    background-color: lighten($body-bg, 0.5);
    border-color: darken($body-bg, 10);
    box-shadow: none;
  }

  &.smallBtn {
    width: 36px;
    height: 36px;
    min-width: 36px;
  }
}