@import '../../../../../../shared/styles/variable.scss';

.menuVerticalItem {
  height: 40px;
  margin: 1px 8px;
  cursor: pointer;
  text-decoration: none;
  width: calc(100% - 16px);
  padding-right: 12px;
  border-radius: $border-radius;
  position: relative;
  transition: all 0.4s ease;
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);

  & .navItemIcon {
    font-size: 20px;
    display: block;
    margin-right: 16px;
    transition: all 0.4s ease;

    [dir=rtl] & {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  & .navItemText {
    font-weight: $font-weight-medium;
    font-size: 14px;
    transition: all 0.4s ease;
  }

  &.active {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.87);
  }

  &.standardMenu {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding-left: 30px;
    padding-right: 12px;
    border-radius: 0;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 4px;
      background-color: transparent;
    }

    &.active:after {
      background-color: $primary;
    }
  }

  &.roundedMenu {
    margin-right: 16px;
    margin-left: 0;
    width: calc(100% - 16px);
    padding-left: 30px;
    padding-right: 12px;
    border-radius: 0 $border-radius-30 $border-radius-30 0;
  }

  &.roundedMenuReverse {
    margin-left: 16px;
    margin-right: 0;
    width: calc(100% - 16px);
    padding-left: 14px;
    padding-right: 12px;
    border-radius: $border-radius-30 0 0 $border-radius-30;
  }
}

.navItemContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.navBadge {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: $border-radius-circle;
}